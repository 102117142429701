<template>
  <custom-select
    v-bind="$attrs"
    v-on="$listeners"
    :options="genders"
    :value="value"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      genders: [
        { title: 'Male', value: 'male' },
        { title: 'Female', value: 'female' }
      ],
    };
  },
};
</script>
